import React from 'react';
import Layout from '../layouts/index';
import SEO from '../components/SEO';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-404">
        <SEO title="404" />
        <div className="container">
          <div className="row">
            <header className="col-12">
              {' '}
              <h1>Not Found</h1>
            </header>
            <div className="col-12">
              <h2>You just hit a page that doesn&#39;t exist...</h2>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
